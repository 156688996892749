import supportedBrowsers from 'supportedBrowsers'

const supportedBrowsersRegex = new RegExp(supportedBrowsers)

const verifyBrowserVersion = () => {
  const node = document.querySelector('.js-unsupported-browser-warning')
  const isBrowserSupported = supportedBrowsersRegex.test(navigator.userAgent)
  if (!isBrowserSupported) {
    node?.classList.remove('hidden')
  }
}

document.addEventListener('DOMContentLoaded', verifyBrowserVersion)
